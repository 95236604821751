import store from '../store'

const routes = [
  {
    path: "/",
    component: () => import("@/layout/dashboard/DashboardLayout"),
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
      requiresNonOperator: true,
    },
    beforeEnter: (to, from, next) => {
      const user = store.getters['auth/user']
      const preloads = [
        store.dispatch('settings/load', {}),
      ]
      if (user.is_admin) {
        preloads.push(
          store.dispatch('users/load', {}),
        )
      }
      Promise.all(preloads)
      next()
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/pages/Dashboard"),
      },
      {
        path: "/tube-label",
        name: "tube label",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "turf",
            component: () => import("@/layout/default"),
            meta: {
              requiresTurf: true
            },
            children: [
              {
                path: "",
                component: () => import("@/pages/TubeLabel/Turf/Index")
              },
            ]
          },
          {
            path: "multi",
            component: () => import("@/layout/default"),
            meta: {
              requiresMulti: true
            },
            children: [
              {
                path: "",
                component: () => import("@/pages/TubeLabel/Multi/Index")
              },
            ]
          },
        ]
      },
      {
        path: "/shipping-label",
        name: "carton label",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/ShippingLabel/Index")
          },
        ]
      },
      {
        path: "/packing-slip",
        name: "packing slip",
        component: () => import("@/layout/default"),
        children: [
          {
            path: "",
            component: () => import("@/pages/PackingSlip/Index")
          },
        ]
      },
      {
        path: "/manage",
        name: "manage",
        component: () => import("@/layout/default"),
        meta: {
          requiresAdmin: true
        },
        children: [
          {
            path: "part-numbers",
            component: () => import("@/pages/PartNumber/Index")
          },
          {
            path: "part-number-attributes",
            component: () => import("@/pages/PartNumber/Attributes")
          },
          {
            path: "carriers",
            component: () => import("@/pages/Manage/Carriers")
          },
          {
            path: "users",
            component: () => import("@/pages/Manage/Users")
          },
          {
            path: "customers",
            component: () => import("@/pages/Manage/Customers")
          },
        ],
      },
    ]
  },
  {
    path: '/',
    name: 'auth',
    component: () => import("@/layout/auth/AuthLayout"),
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import("@/pages/Auth/Login"),
      },
      {
        path: 'login_sso',
        name: 'login_sso',
        component: () => import("@/pages/Auth/LoginSSO"),
      },
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import("@/pages/Auth/Logout"),
  },
  { path: "*", component: () => import("@/pages/NotFound") }
];

export default routes;
