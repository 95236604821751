import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import VueResource from 'vue-resource'
Vue.use(VueResource)

import IdleVue from 'idle-vue'
const idleTime = 1000 * 60 *30
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime,
})

import Notify from "vue-notifyjs";
Vue.use(Notify, {
  horizontalAlign: 'right',
  verticalAlign: 'top',
});
import "vue-notifyjs/themes/default.css"

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css'

import moment from 'moment'
Vue.prototype.moment = moment

Vue.filter('moment', function (value, format = '') {
  return moment(value).format(format)
})

import VueBarcode from 'vue-barcode'
Vue.component('VueBarcode', VueBarcode)

String.prototype.formatNumber = function (decimal = 0) {
  if (!this) return ''
  let formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  return formatter.format(this);
}
Vue.filter('formatNumber', function (value, decimal = 0) {
  return (value + '').formatNumber(decimal)
})

Vue.http.options.root = store.getters.apiHost
Vue.http.interceptors.push((request, next) => {
  if (!request.url.toLowerCase().startsWith('auth/')) {
    const last_action_time = localStorage.getItem('last_action')
    if (last_action_time && new Date(last_action_time) < new Date(Date.now() - idleTime)) {
      store.dispatch('auth/logout')
      return
    }
  }
  next((response) => {
    if (response.status == 401) {
      store.dispatch('auth/logout')
    }
    if (!request.url.toLowerCase().includes('auth/get-user') && response.status == 200) {
      localStorage.setItem('last_action', new Date())
    }
  });
})
const token = localStorage.getItem('token')
if (token) {
  Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
}

(() => token ? store.dispatch('auth/getUser') : Promise.resolve())().finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    created: () => {
    },
    onIdle() {
      if (store.getters['auth/isLoggedIn']) {
        store.dispatch('auth/logout')
      }
    },
  }).$mount('#app')
})

// custom prototypes
String.prototype.toCamelCase = function() {
  const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
  let string = this.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ').reduce((result, word) => result + capitalize(word.toLowerCase()))
  return string.charAt(0).toLowerCase() + string.slice(1)
}

String.prototype.validateEmail = function() {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(this.toLowerCase())
}

Date.getDateString = (t = new Date()) => {
  var year = t.getFullYear()
  var month = '0' + (t.getMonth() + 1)
  var day = '0' + t.getDate()
  return `${year}-${month.substr(-2)}-${day.substr(-2)}`
}

Array.equalsIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false
  const uniqueValues = new Set([...a, ...b])
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length
    const bCount = b.filter(e => e === v).length
    if (aCount !== bCount) return false
  }
  return true
}

Array.sortByValueString = (key) => (a, b) => {
  const valueA = a[key].toUpperCase(); // ignore upper and lowercase
  const valueB = b[key].toUpperCase(); // ignore upper and lowercase
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  // names must be equal
  return 0;
}