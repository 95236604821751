import Vue from 'vue'
import router from "@/router/index"

export default {
  namespaced: true,
  state: () => ({
    auth_status: '',
    token: localStorage.getItem('token') || '',
    user : null,
  }),
  getters: {
    isLoggedIn: state => !!state.token,
    status: state => state.auth_status,
    loading: state => state.auth_status == 'loading',
    error: state => state.auth_status == 'error',
    success: state => state.auth_status == 'success',
    user: state => state.user || {},
    token: state => state.token || '',
  },
  mutations: {
    auth_request(state) {
      state.auth_status = 'loading'
    },
    auth_success(state, {token, user}) {
      state.auth_status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.auth_status = 'error'
    },
    logout(state) {
      state.auth_status = ''
      state.token = ''
      state.user = null
    },
    user_fill(state, {user}) {
      state.auth_status = 'success'
      state.user = user
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/login', payload)
        .then(resp => {
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    login_as({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/login_as', payload)
        .then(resp => {
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    login_sso({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.headers.common['Authorization'] = 'Bearer ' + payload.token
        Vue.http.post('auth/login_sso')
        .then(resp => {
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          resolve(resp.data)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
    logout({commit, dispatch}) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('last_action')
        delete Vue.http.headers.common['Authorization']
        router.push('/login', function() {
          dispatch('notify', {
            message: 'You are logged out.',
          }, { root: true })
          resolve()
        });
      })
    },
    getUser({commit}) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/get-user', {})
        .then(resp => {
          const {
            user,
          } = resp.data
          commit('user_fill', {user})
          resolve()
        }).catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
    update({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/update-user', payload)
        .then(resp => {
          const {
            user,
          } = resp.data
          commit('user_fill', {user})
          resolve()
        }).catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
  }

}