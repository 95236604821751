import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import store from '../store'
Vue.use(VueRouter)

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['auth/isLoggedIn']
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  let requiresTurf = to.matched.some(record => record.meta.requiresTurf)
  let requiresMulti = to.matched.some(record => record.meta.requiresMulti)
  let requiresNonOperator = to.matched.some(record => record.meta.requiresNonOperator)

  if (!isLoggedIn && requiresAuth) {
    next('/login');
    return
  }

  if (isLoggedIn && (to.name === 'login')) {
    next('/')
    return
  }

  let authUser = store.getters['auth/user'];

  if (authUser && authUser.is_operator && requiresNonOperator) {
    store.dispatch('auth/logout')
    return
  }

  if (requiresAdmin && (!authUser || !authUser.is_admin)) {
    store.dispatch('notify', {
      message: 'You are not allowed to manage this feature.',
      type: 'danger',
    })
    next('/')
    return
  }

  if (requiresTurf && (!authUser || !authUser.applications.includes(1))) {
    store.dispatch('notify', {
      message: 'You are not allowed to manage this Turf app.',
      type: 'danger',
    })
    next('/')
    return
  }
  if (requiresMulti && (!authUser || !authUser.applications.includes(2))) {
    store.dispatch('notify', {
      message: 'You are not allowed to manage this Multi app.',
      type: 'danger',
    })
    next('/')
    return
  }

  store.dispatch('setTubeLabelApp', '')
  if (requiresTurf && authUser && authUser.applications.includes(1)) {
    store.dispatch('setTubeLabelApp', 'Turf')
  }
  if (requiresMulti && authUser && authUser.applications.includes(2)) {
    store.dispatch('setTubeLabelApp', 'Multi')
  }

  localStorage.setItem('last_action', new Date())
  next();
})

export default router
