import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
    size: 10,
    page: 1,

    total: 0,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
    size (state) {
      return state.size || 10
    },
    page (state) {
      return state.page || 1
    },
    total (state) {
      return state.total || 0
    },
    getTubeLabelDetails: (state, getters, rootState, rootGetters) => (tubeLabel) => {
      const shippingLabel = rootGetters['shipping_labels/data'].find(shippingLabel => shippingLabel.carton_number == tubeLabel.box_number)
      const part_number_id = tubeLabel.part_number_id || (shippingLabel && shippingLabel.part_number_id)
      const partNumber = part_number_id ? rootGetters['settings/part_numbers/data'].find(partNumber => partNumber.id == part_number_id) : null
      const colorAttribute = rootGetters['settings/part_numbers/attributes'].find(attribute => attribute.name == 'Color')
      const finishAttribute = rootGetters['settings/part_numbers/attributes'].find(attribute => attribute.name == 'Finish')
      return {
        ...tubeLabel,
        'part_number/merge_number': partNumber ? partNumber.merge_number : '',
        'part_number/color': partNumber ? rootGetters['settings/part_numbers/getAttributeValues'](colorAttribute.id).find(attributeValue => attributeValue.id == partNumber.meta[colorAttribute.id]).value : '',
        'part_number/finish': partNumber ? rootGetters['settings/part_numbers/getAttributeValues'](finishAttribute.id).find(attributeValue => attributeValue.id == partNumber.meta[finishAttribute.id]).value : '',
        isEditable: tubeLabel.created_by == rootGetters['auth/user'].id || rootGetters['auth/user'].is_admin,
      }
    },
  },
  actions: {
    load ({commit, getters}, options = {}) {
      const payload = {
        size: getters.size,
        page: getters.page,
        ...options,
      }
      commit('setLoading', true)
      commit('setError', null)
      return Vue.http.post('tube_labels/load', payload)
      .then(response => response.data)
      .then(response => {
        const {
          success,
          message,
          error,
          data,
        } = response
        if (!success) {
          throw message || error || 'Unknown Error'
        }
        const tube_labes = data.tube_labels.map(tubeLabel => getters.getTubeLabelDetails(tubeLabel))
        commit('setData', tube_labes)
        commit('setTotal', response.data.total)
        return tube_labes
      })
      .catch(e => { 
        commit('setError', e)
        commit('setData', [])
        commit('setTotal', 0)
        return []
      })
      .finally(() => commit('setLoading', false))
    },
    submit ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('tube_labels/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          const tube_label = getters.getTubeLabelDetails(data)
          commit('updateData', tube_label)
          resolve(tube_label)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('tube_labels/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.unshift(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },
    setTotal (state, payload) {
      state.total = payload
    },

    setSize (state, payload) {
      state.size = payload
    },
    setPage (state, payload) {
      state.page = payload
    },

    resetPage (state) {
      state.page = 1
    },
  },
}