import applications from './applications'
import customers from './customers'
import part_numbers from './part_numbers'
import carriers from './carriers'

export default {
  applications,
  customers,
  part_numbers,
  carriers,
}
