import auth from './auth'
import users from './users'
import tube_labels from './tube_labels'
import tube_labels_multi from './tube_labels_multi'
import shipping_labels from './shipping_labels'
import packing_slips from './packing_slips'
import settings from './settings'

export default {
  auth,
  users,
  tube_labels,
  tube_labels_multi,
  shipping_labels,
  packing_slips,
  settings,
}
