import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
    attributes: [],
    attributeValues: [],
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
    attributes (state) {
      return state.attributes || []
    },
    getAttributeValues: (state) => (attribute_id, is_all = false) => {
      if (!attribute_id) {
        return []
      }
      return state.attributeValues.filter(attributeValue => attributeValue.part_number_attribute_id == attribute_id && (is_all || attributeValue.is_active)).sort(Array.sortByValueString('value')) || []
    },
  },
  actions: {
    load ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('part_numbers/load', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setData', data.part_numbers)
          commit('setAttributes', data.part_number_attributes)
          commit('setAttributeValues', data.part_number_attribute_values)
          resolve(getters.data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('part_numbers/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    submitAttributeValue ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        let findExistingAttributeValueIndex = getters.getAttributeValues(payload.part_number_attribute_id, true).findIndex(attributeValue => attributeValue.id != payload.id && attributeValue.value.trim().toLowerCase() == payload.value.trim().toLowerCase())
        if (findExistingAttributeValueIndex > -1) {
          commit('setError', 'Cannot submit duplicate value!')
          commit('setLoading', false)
          return reject()
        }
        Vue.http.post('part_numbers/submit_attribute_value', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateAttributeValue', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    }
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, {
          ...state.data[rowIndex],
          ...target
        })
      } else {
        state.data.push(target)
      }
    },
    setAttributes (state, payload) {
      state.attributes = payload
    },
    setAttributeValues (state, payload) {
      state.attributeValues = payload
    },
    updateAttributeValue (state, target) {
      const attributeValueIndex = state.attributeValues.findIndex(attributeValue => attributeValue.id == target.id)
      if (attributeValueIndex > -1) {
        state.attributeValues.splice(attributeValueIndex, 1, target)
      } else {
        state.attributeValues.push(target)
      }
    }
  },
}