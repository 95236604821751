import Vue from 'vue'
import Vuex from 'vuex'
import { Notification } from "vue-notifyjs"

Vue.use(Vuex)

import modules from './modules'

const initialState = () => ({
  error: null,
  loading: false,
  appTitle: 'Label Printing',
  appVersion: process.env.VUE_APP_VERSION || '1.0.0',
  apiHost: process.env.NODE_ENV == 'production' ? 'https://label.ecofibre.com/api' : process.env.VUE_APP_API_HOST,
  currentTubeLabelApp: '',
})

export default new Vuex.Store({
  state: initialState,
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setTubeLabelApp (state, app) {
      state.currentTubeLabelApp = app
    },
  },
  actions: {
    notify({}, payload) {
      Notification.notify(payload)
    },
    setTubeLabelApp({commit}, payload) {
      commit('setTubeLabelApp', payload)
    },
  },
  getters: {
    error: state => state.error,
    loading: state => state.loading,
    appTitle: state => state.appTitle,
    apiHost: state => state.apiHost,
    tubeLabelApp: state => state.currentTubeLabelApp,
  },
  modules,
})
